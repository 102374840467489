import React from 'react';
import BaseLayout from '../base-layout';
import classNames from 'classnames';
import Styles from './LoaderLayout.module.scss';

const LoadingLayout: React.FC = () => {
	return (
		<div className="px-3 md:px-[12rem] pb-[5rem]  grid grid-cols-1 box-border overflow-hidden h-[100vh]">
			<div className={classNames('h-[100px] w-full bg-[#ccc] flex items-center mt-[2rem]', Styles.Loading)}>
				<div className={classNames('h-[10px] w-full bg-[#fAfAfA] rounded-lg px-8')} />
			</div>
			<div
				className="md:mt-[4rem] mt-[2rem] py-[2rem] bg-stale-100"
				style={{
					backgroundColor: 'none',
					opacity: 0.9
				}}
			>
				<div className="grid grid-cols-1 gap-[6rem] items-center relative rounded-lg">
					<div className={classNames('rounded-lg h-[400px] w-full bg-[#ccc]', Styles.Loading)}></div>
					<div className={classNames('rounded-lg h-[400px] w-full bg-[#ccc]', Styles.Loading)}></div>
				</div>
			</div>
		</div>
	);
};

export default LoadingLayout;
