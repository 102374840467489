import React, { FC } from 'react';
import styles from './logo.module.scss';
import classNames from 'classnames';

interface LogoProps {
	logoURL: string | undefined;
}

const Logo: FC<LogoProps> = ({ logoURL }) => {
	return logoURL ? (
		<img className={classNames(styles.logo, 'mt-0')} src={logoURL} alt="logo" />
	) : (
		<svg
			className={styles.logo}
			width="250"
			height="80"
			viewBox="0 0 187 60"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M186.949 59.0649H0V0.0641365H176.042L186.949 11.744V59.0649Z" fill="#7684FF" />
			<path
				d="M177.321 22.3395H175.912V20.9311C175.912 20.3315 175.426 19.8454 174.826 19.8454H174.695C174.096 19.8454 173.61 20.3315 173.61 20.9311V22.3395H172.201C171.602 22.3395 171.115 22.8256 171.115 23.4249V23.5561C171.115 24.1557 171.602 24.6418 172.201 24.6418H173.61V26.0501C173.61 26.6498 174.096 27.1359 174.695 27.1359H174.826C175.426 27.1359 175.912 26.6498 175.912 26.0501V24.6418H177.321C177.92 24.6418 178.406 24.1557 178.406 23.5561V23.4249C178.406 22.8256 177.92 22.3395 177.321 22.3395Z"
				fill="#F89E27"
			/>
			<path
				d="M186.847 11.6799H176.089V-3.91006e-05C176.089 -3.91006e-05 187.36 11.6799 186.847 11.6799Z"
				fill="#E0E3FF"
			/>
			<path
				d="M177.53 22.3395H175.912V20.7218C175.912 20.2376 175.52 19.8454 175.036 19.8454H174.486C174.002 19.8454 173.61 20.2376 173.61 20.7218V22.3395H171.992C171.508 22.3395 171.115 22.7317 171.115 23.2155V23.7657C171.115 24.2496 171.508 24.6418 171.992 24.6418H173.61V26.2595C173.61 26.7436 174.002 27.1359 174.486 27.1359H175.036C175.52 27.1359 175.912 26.7436 175.912 26.2595V24.6418H177.53C178.014 24.6418 178.406 24.2496 178.406 23.7657V23.2155C178.406 22.7317 178.014 22.3395 177.53 22.3395Z"
				fill="#F89C27"
			/>
			<path
				d="M12.4082 42.7186V18.3882H15.6032L24.4232 36.6884L33.1922 18.3882H36.4174V42.7035H33.0722V26.0623L25.2154 42.7186H23.6103L15.7382 26.0623V42.7186H12.4082Z"
				fill="#E0E3FF"
			/>
			<path
				d="M49.0743 39.8983C50.8203 39.8983 52.1224 39.3105 52.9834 38.1344C53.8474 36.9555 54.2763 35.4373 54.2763 33.5803C54.2763 31.6754 53.8413 30.1544 52.9684 29.0084C52.0953 27.8653 50.7964 27.2924 49.0743 27.2924C47.8893 27.2924 46.9175 27.5624 46.1494 28.0963C45.3844 28.6304 44.8144 29.3684 44.4423 30.3104C44.0734 31.2523 43.8873 32.3385 43.8873 33.5803C43.8873 35.4945 44.3255 37.0244 45.2044 38.1765C46.0833 39.3222 47.3733 39.8983 49.0743 39.8983ZM49.0743 43.2286C47.2475 43.2286 45.6665 42.8143 44.3255 41.9925C42.9844 41.1704 41.9523 40.0366 41.2263 38.5904C40.4974 37.1412 40.1345 35.4702 40.1345 33.5803C40.1345 31.6754 40.5063 29.9983 41.2502 28.5524C41.9945 27.1065 43.0355 25.9784 44.3765 25.1744C45.7173 24.3702 47.2833 23.9652 49.0743 23.9652C50.8985 23.9652 52.4795 24.3763 53.8206 25.1984C55.1616 26.0202 56.1966 27.1544 56.9315 28.5944C57.6633 30.0373 58.0294 31.6964 58.0294 33.5803C58.0294 35.4823 57.6605 37.1563 56.9223 38.6055C56.1844 40.0544 55.1434 41.1855 53.8055 42.0014C52.4645 42.8203 50.8864 43.2286 49.0743 43.2286Z"
				fill="#E0E3FF"
			/>
			<path
				d="M74.5535 42.7186V33.7484C74.5535 33.0402 74.4935 32.3085 74.3764 31.5583C74.2564 30.8114 74.0314 30.1154 73.6985 29.4733C73.3685 28.8313 72.9003 28.3124 72.2975 27.9193C71.6943 27.5264 70.9086 27.3284 69.9394 27.3284C69.3094 27.3284 68.7126 27.4304 68.1484 27.6404C67.5844 27.8503 67.0923 28.1833 66.6695 28.6453C66.2495 29.1073 65.9163 29.7163 65.6734 30.4723C65.4306 31.2254 65.3105 32.1435 65.3105 33.2264L63.1145 32.3985C63.1145 30.7423 63.4235 29.2784 64.0445 28.0123C64.6624 26.7462 65.5534 25.7562 66.7144 25.0484C67.8723 24.3373 69.2765 23.9802 70.9204 23.9802C72.1804 23.9802 73.2396 24.1844 74.0975 24.5923C74.9524 24.9944 75.6485 25.5283 76.1824 26.1883C76.7195 26.8454 77.1274 27.5624 77.4095 28.3424C77.6884 29.1163 77.8805 29.8784 77.9823 30.6224C78.0845 31.3664 78.1355 32.0202 78.1355 32.5842V42.7186H74.5535ZM61.7285 42.7186V24.4723H64.8874V29.7434H65.3105V42.7186H61.7285Z"
				fill="#E0E3FF"
			/>
			<path
				d="M94.9955 42.7186V29.5904H94.5905V18.3882H98.1396V42.7186H94.9955ZM90.0456 40.0333C91.2066 40.0333 92.1516 39.7573 92.8837 39.2054C93.6155 38.6533 94.1526 37.8945 94.4887 36.9223C94.8276 35.9563 94.9955 34.8403 94.9955 33.5803C94.9955 32.3175 94.8247 31.2043 94.4826 30.2323C94.1376 29.2663 93.6094 28.5104 92.8926 27.9704C92.1784 27.4304 91.2695 27.1604 90.1656 27.1604C88.9924 27.1604 88.0296 27.4453 87.2764 28.0184C86.5207 28.5943 85.9596 29.3684 85.5936 30.3344C85.2275 31.3033 85.0447 32.3864 85.0447 33.5803C85.0447 34.7863 85.2275 35.8724 85.5936 36.8505C85.9596 37.8223 86.5114 38.5966 87.2496 39.1723C87.9874 39.7483 88.9206 40.0333 90.0456 40.0333ZM89.5567 43.2286C87.8556 43.2286 86.3886 42.8025 85.1554 41.9594C83.9226 41.1165 82.9686 39.9644 82.2994 38.5065C81.6276 37.0455 81.2944 35.4045 81.2944 33.5803C81.2944 31.7444 81.6305 30.1004 82.3087 28.6543C82.9836 27.2084 83.9436 26.0623 85.1886 25.2223C86.4336 24.3852 87.9125 23.9652 89.6224 23.9652C91.3477 23.9652 92.7966 24.3852 93.9755 25.2223C95.1515 26.0623 96.0426 27.2084 96.6425 28.6604C97.2457 30.1154 97.5486 31.7564 97.5486 33.5803C97.5486 35.4045 97.2425 37.0424 96.6365 38.4944C96.0276 39.9494 95.1305 41.1014 93.9487 41.9504C92.7666 42.8025 91.3027 43.2286 89.5567 43.2286Z"
				fill="#E0E3FF"
			/>
			<path
				d="M106.401 30.6403H112.567C112.794 30.6403 113.043 30.6284 113.319 30.6044C113.596 30.5834 113.857 30.5443 114.106 30.4874C114.825 30.3163 115.408 30.0043 115.845 29.5484C116.287 29.0924 116.602 28.5674 116.79 27.9704C116.983 27.3734 117.079 26.7765 117.079 26.1794C117.079 25.5823 116.983 24.9884 116.79 24.3941C116.602 23.8063 116.287 23.2813 115.845 22.8252C115.408 22.3695 114.825 22.0542 114.106 21.8863C113.857 21.8202 113.596 21.7752 113.319 21.7513C113.043 21.7302 112.794 21.7184 112.567 21.7184H106.401V30.6403ZM102.87 42.7186V18.3882H112.704C112.938 18.3882 113.233 18.3971 113.581 18.4153C113.932 18.4303 114.264 18.4664 114.579 18.5264C115.929 18.7393 117.06 19.2014 117.966 19.9124C118.873 20.6203 119.551 21.5142 120.004 22.5974C120.454 23.6773 120.679 24.8744 120.679 26.1794C120.679 27.4963 120.454 28.6963 120.004 29.7764C119.551 30.8594 118.873 31.7563 117.966 32.4642C117.06 33.1724 115.929 33.6345 114.579 33.8503C114.264 33.8923 113.928 33.9285 113.574 33.9524C113.218 33.9735 112.929 33.9852 112.704 33.9852H106.401V42.7186H102.87Z"
				fill="white"
			/>
			<path d="M124.396 42.7186V17.8843H127.927V42.7186H124.396Z" fill="white" />
			<path
				d="M138.775 40.3365C139.777 40.3365 140.638 40.1626 141.361 39.8054C142.081 39.4483 142.66 38.9983 143.101 38.4466C143.539 37.8945 143.827 37.3184 143.962 36.7212C144.154 36.1813 144.262 35.5723 144.292 34.8974C144.319 34.2223 144.334 33.6824 144.334 33.2743L145.483 33.6974C144.367 33.8684 143.353 34.0185 142.441 34.1534C141.529 34.2884 140.704 34.4203 139.966 34.5523C139.228 34.6785 138.571 34.8346 137.998 35.0144C137.512 35.1824 137.083 35.3863 136.705 35.6234C136.327 35.8605 136.03 36.1484 135.808 36.4845C135.589 36.8233 135.481 37.2345 135.481 37.7205C135.481 38.1915 135.598 38.6265 135.835 39.0284C136.072 39.4273 136.432 39.7483 136.915 39.9826C137.401 40.2193 138.019 40.3365 138.775 40.3365ZM138.148 43.2286C136.798 43.2286 135.667 42.9764 134.752 42.4754C133.84 41.9743 133.15 41.3082 132.685 40.4805C132.217 39.6522 131.983 38.7433 131.983 37.7505C131.983 36.8295 132.145 36.0163 132.472 35.3202C132.799 34.6213 133.282 34.0274 133.924 33.5384C134.569 33.0464 135.355 32.6503 136.291 32.3443C137.101 32.1103 138.019 31.9003 139.045 31.7205C140.071 31.5403 141.148 31.3724 142.282 31.2134C143.413 31.0544 144.535 30.8984 145.651 30.7423L144.367 31.4504C144.391 30.0194 144.088 28.9574 143.464 28.2644C142.837 27.5744 141.766 27.2263 140.245 27.2263C139.288 27.2263 138.409 27.4483 137.608 27.8924C136.81 28.3393 136.252 29.0743 135.937 30.1004L132.64 29.0864C133.093 27.5204 133.951 26.2723 135.217 25.3512C136.486 24.4273 138.172 23.9652 140.278 23.9652C141.91 23.9652 143.335 24.2445 144.544 24.8023C145.756 25.3602 146.641 26.2423 147.205 27.4453C147.499 28.0424 147.679 28.6724 147.745 29.3384C147.814 30.0043 147.847 30.7184 147.847 31.4833V42.7186H144.721V38.5483L145.33 39.0884C144.574 40.4834 143.614 41.5243 142.45 42.2025C141.283 42.8864 139.849 43.2286 138.148 43.2286Z"
				fill="white"
			/>
			<path
				d="M165.046 42.7186V33.7484C165.046 33.0402 164.989 32.3085 164.869 31.5583C164.752 30.8114 164.527 30.1154 164.194 29.4733C163.861 28.8313 163.393 28.3124 162.79 27.9193C162.19 27.5264 161.404 27.3284 160.434 27.3284C159.802 27.3284 159.205 27.4304 158.644 27.6404C158.08 27.8503 157.588 28.1833 157.165 28.6453C156.742 29.1073 156.409 29.7163 156.169 30.4723C155.926 31.2254 155.806 32.1435 155.806 33.2264L153.606 32.3985C153.606 30.7423 153.919 29.2784 154.537 28.0123C155.157 26.7462 156.046 25.7562 157.207 25.0484C158.368 24.3373 159.769 23.9802 161.413 23.9802C162.675 23.9802 163.735 24.1844 164.59 24.5923C165.448 24.9944 166.141 25.5283 166.678 26.1883C167.212 26.8454 167.62 27.5624 167.902 28.3424C168.184 29.1163 168.376 29.8784 168.478 30.6224C168.577 31.3664 168.628 32.0202 168.628 32.5842V42.7186H165.046ZM152.224 42.7186V24.4723H155.382V29.7434H155.806V42.7186H152.224Z"
				fill="white"
			/>
		</svg>
	);
};

export default Logo;
