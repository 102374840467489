import React, { FC, useState } from 'react';

import BaseLayout from '@components/layouts/base-layout';

import DynamicLayout from '@pages/blog/dynamic-layout';

const BlogPage: FC = () => {
	return (
		<BaseLayout>
			<DynamicLayout />
		</BaseLayout>
	);
};

export default BlogPage;
