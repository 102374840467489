import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { NavigationProvider } from './contexts/navigation/navigation';
import AppRoutes from './components/app-routes/app-routes';

//Need to update the app since the app will render data based on links included on external files
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			refetchOnWindowFocus: false
		}
	}
});

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<NavigationProvider>
				<AppRoutes />
			</NavigationProvider>
		</QueryClientProvider>
	);
}

export default App;
