import React from 'react';
import classNames from 'classnames';
import { IconsProps } from './icons';

export const Arrow = ({ className }: IconsProps) => (
	<svg
		className={classNames(className)}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M8 4L16 12L8 20" stroke="#7684FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
	</svg>
);
