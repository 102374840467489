import React, { FC } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BlogPage from '@pages/blog/blog';
import NoMatchPage from '@pages/no-match';

const AppRoutes = () => {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<BlogPage />} />
				<Route path="/404" element={<NoMatchPage />} />
			</Routes>
		</Router>
	);
};

export default AppRoutes;
