import React, { FC } from 'react';
import classNames from 'classnames';

export interface TitleProps {
	variant: 'h1' | 'h2' | 'h3';
	align?: 'left' | 'center' | 'right';
	fontSize?: string;
	text: string;
	styles?: React.CSSProperties;
	colour?: 'text-black-primary' | 'text-purple-primary';
	className?: string;
}

const Title: FC<TitleProps> = ({ align, variant, text, styles, colour, className }): JSX.Element => {
	const HeaderType = variant as keyof JSX.IntrinsicElements;
	const defaultColour = colour ? colour : 'text-[#212427]';
	return (
		<HeaderType
			className={classNames('text-4xl', className ?? '')}
			style={{
				...styles,
				textAlign: align
			}}
		>
			{text}
		</HeaderType>
	);
};

export default Title;
