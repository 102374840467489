import React, { FC } from 'react';
import classNames from 'classnames';
import style from './BaseLayout.module.scss';
import Navbar from '../navBar/navbar';

export interface BaseLayoutProps {
	children: React.ReactNode;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
	return (
		<>
			<Navbar />
			<article
				className={classNames(
					'bg-white grid grid-cols-1 box-border sm:mx-16 md:mx-56 lg:mx-112 land-sm:mx-48 land-md:mx-112',
					style.baseLayout
				)}
			>
				{children}
			</article>
		</>
	);
};

export default BaseLayout;
