import React, { useRef, useState } from 'react';
import Title from '../atoms/heading/heading';
import ReactPlayer from 'react-player';
import Styles from './Video.module.scss';
import Header from '@components/header/header';

export interface VideoProps {
	data: any;
	styles?: React.CSSProperties;
}

const VideoPlayer: React.FC<VideoProps> = ({ data, styles }): JSX.Element => {
	const [playing, setPlaying] = useState(false);
	const [isError, setIsError] = useState(false);
	const videoRef = useRef<any>(null);
	const isYoutube = data?.data?.videoCode?.includes('youtube');

	const onPlay = () => {
		setPlaying(true);
	};

	const onPause = () => {
		setPlaying(false);
	};

	return (
		<div className="relative w-full h-[425px] rounded-lg mt-[2rem]">
			{isError ? (
				<div className="flex items-center justify-center w-full h-full bg-light-gray-800 opacity-75">
					<Title text="Video not found" variant="h2" fontSize="24px" align="center" />
				</div>
			) : (
				<>
					<ReactPlayer
						controls
						className=""
						width={'100%'}
						height={'100%'}
						url={data?.data?.videoCode}
						onPlay={onPlay}
						refs={videoRef}
						playing={playing}
						onPause={onPause}
						onStart={onPlay}
						light={!isYoutube ? true : '/images/video-placeholder.png'}
						playIcon={
							<button
								className={`rounded-full h-[8.25rem] w-[8.25rem]  my-0 mx-auto`}
								onClick={onPlay}
								style={{ display: playing ? 'none' : 'block' }}
							>
								<img src="/svg/playbutton.svg" alt="Play button" />
							</button>
						}
						onError={e => {
							setIsError(true);
						}}
						config={{
							youtube: {
								embedOptions: {
									autoplay: 0,
									modestbranding: 1,
									showinfo: 1
								}
							}
						}}
						class="rounded-lg"
					/>
				</>
			)}
		</div>
	);
};

export default VideoPlayer;
