import classNames from 'classnames';
import React, { FC } from 'react';

interface ContentProps {
	children: React.ReactNode;
	className?: string;
	align?: 'left' | 'center' | 'right';
	cols?: number;
	styles?: React.CSSProperties;
}
const MediaContainer: FC<ContentProps> = ({ align, children, className, cols, styles }): JSX.Element => {
	return (
		<div className="flex w-full justify-center">
			<section
				className={classNames(
					`flex flex-col gap-[1rem] max-w-[2750px] w-full pb-[6.25rem] pt-[60px] sm:pt-16 sm:px-24 md:px-32 lg:px-80 land-sm:pt-16 land-sm:px-32 land-md:px-80 relative `,
					className
				)}
				style={{ ...(styles ?? {}) }}
			>
				{children}
			</section>
		</div>
	);
};
export default MediaContainer;
