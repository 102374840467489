import classNames from 'classnames';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface BreadCrumProps {
	children?: React.ReactNode;
	selected?: boolean;
	uuid: string;
}

export const Breadcrum: FC<BreadCrumProps> = ({ children, selected = false, uuid }: BreadCrumProps): JSX.Element => {
	const origin = window.location.origin;
	const url = `${origin}/?uuid=${uuid}`;

	return (
		<Link
			to={url}
			className={classNames('font-normal shrink-1 truncate overflow-hidden no-underline', selected && 'text-purple')}
		>
			{children}
		</Link>
	);
};
