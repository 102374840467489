import React, { FC, useEffect, useState, useRef } from 'react';
import { renderToString } from 'react-dom/server';
import VideoPlayer from '@components/video/video';
import MediaContent from '@components/media-container/media-container';

import Description from '../../components/atoms/description/description';
import { trimKeys } from '../../helpers/helpers';
import Heading from '../../components/atoms/heading/heading';

import Image from '@components/atoms/image/image';
import { useNavigation } from '../../contexts/navigation/navigation';
import { BlogService } from '@services/blog/blog';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoadingLayout from '@components/layouts/loading-layout/loading-layout';

import * as cheerio from 'cheerio';
import Header from '@components/header/header';
import Lightbox from 'react-image-lightbox';

const dynamicStyleString = `
<style>
	.img-container, .video-container { 
		display: flex; 
		justify-content: center; 
		flex-direction: column;
		figcaption {
			font-size: 15px;
			font-weight: 400;
			--tw-text-opacity: 1;
			color: rgb(143 143 143 / var(--tw-text-opacity));
			margin-top: 0.75rem;
			margin-bottom: 1rem;
		}
	}
	
</style>`;

const BlogPage: FC = () => {
	const [isLoading, setShowLoader] = useState(true);
	const { url, addPath } = useNavigation();
	const navigate = useNavigate();
	const containerRef = useRef<HTMLDivElement>(null);
	const [contentProcessed, setContentProcessed] = useState<string>('');
	const [searchParams] = useSearchParams();
	const myParam = {
		uuid: ''
	};

	myParam.uuid = searchParams.get('uuid') ?? '';

	const { data, isFetching } = useQuery(
		['blog', url, searchParams.get('uuid')],
		() => {
			document.title = 'Loading...';
			return BlogService.getNotion(myParam.uuid);
		},
		{
			cacheTime: 0,
			onSuccess: res => {
				setShowLoader(false);
				document.title = res!.title;
			},
			onError: () => {
				document.title = 'Not found';
				navigate('/404');
			}
		}
	);

	useEffect(() => {
		setShowLoader(isFetching);
		setTimeout(() => {
			setShowLoader(false);
		}, 10000);
	}, [url, isFetching]);

	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.style.opacity = '0';
			setTimeout(() => {
				if (containerRef.current) {
					containerRef.current.style.opacity = '1';
					containerRef.current.style.transition = 'opacity 1s ease-in-out';
				}
			}, 300);
		}
	}, [isLoading, data]);

	const renderTextComponents = (indexes: number[]) => {
		return indexes.map((item: any, key: number) => (
			<React.Fragment key={key}>
				<Description
					key={`${key}-${data?.content[item.position].data.text[0]}`}
					data={trimKeys(data?.content[item.position])}
					align="left"
					styles={{
						width: '100%',
						whiteSpace: 'pre-wrap'
					}}
				/>
			</React.Fragment>
		));
	};

	const renderVideoComponents = (indexes: number[]) => {
		return indexes.map((item: any, key: number) => (
			<React.Fragment key={`${key}-${item.position}`}>
				<VideoPlayer data={data?.content[item.position]} />
			</React.Fragment>
		));
	};

	const renderImageComponents = (indexes: number[]) => {
		return indexes.map((item: any, key: number) => (
			<React.Fragment key={`${key}-${data?.content[item.position].data.caption}`}>
				<Image
					src={data?.content[item.position].data.image}
					caption={data?.content[item.position].data.caption}
					alt={data?.content[item.position].data.image}
				/>
			</React.Fragment>
		));
	};
	//let textIndexes: string | any[] = [],videoIndexes: string | any[] = [], imageIndexes: string | any[] = [], fotogridIndex: string | number | never[] = [];
	if (data != null) {
		//textIndexes = counterAndPosition(data[0].content, ['tekst', 'text']); //Language switched to dutch somehow
		//videoIndexes = counterAndPosition(data[0]?.content, ['video']);
		//imageIndexes = counterAndPosition(data[0]?.content, ['image', 'afbeelding']);
		//fotogridIndex = counterAndPosition(data[0]?.content, ['fotogrid'])[0]?.position;
	}

	interface IAnchorData {
		id: string;
		uuid: string | null;
	}

	const [anchorsData, setAnchorsData] = useState<IAnchorData[]>([]);

	const handleClick = (uuid: string | null) => {
		if (uuid) {
			navigate(`/?uuid=${uuid}`);
		}
	};

	// Lightbox
	const [images, setImages] = useState<string[]>([]);
	const [photoIndex, setPhotoIndex] = useState(0);
	const [isOpen, setIsOpen] = useState(false);
	const [key, setKey] = useState(false);
	useEffect(() => {
		setTimeout(() => setKey(!key));
	}, [isOpen]);

	const handleClickPhoto = (event: React.MouseEvent<HTMLButtonElement>) => {
		const index = event.currentTarget.getAttribute('data-index');
		setPhotoIndex(Number(index));
		setIsOpen(true);
	};

	useEffect(() => {
		if (data?.content) {
			const $ = cheerio.load(data?.content);

			// add style on header:
			$('head').append(dynamicStyleString);
			const newAnchorsData: IAnchorData[] = [];
			$('a').each((index, element) => {
				const content = $(element).html();

				const href = $(element).attr('href');
				const url = new URL(href as string);
				const uuid = url.searchParams.get('uuid');
				const id = (uuid || 'uuid') + index;

				const linkComponent = renderToString(
					<a id={id} className="cursor-pointer" dangerouslySetInnerHTML={{ __html: content || '' }} />
				);
				$(element).replaceWith(linkComponent);

				newAnchorsData.push({ id: id, uuid: uuid });
			});

			// Photogrid
			const photogrids = $('.photogrid');
			let indexCount = 0;
			photogrids.each((gridIndex, gridElement) => {
				const $grid = $(gridElement);
				const title = $grid.find('p');
				if ($grid.length > 0) {
					const imgElements = $grid.find('img');
					$grid.append(title);
					const countImages: string[] = [];
					const newContainer = cheerio.load(`<div class="photogrid-container"></div>`);
					const container = newContainer('div');
					imgElements.each((index, img) => {
						const newButton = cheerio.load(`<button class="photogrid-item" data-index="${indexCount}"></button>`);
						const itemButton = newButton('button');

						itemButton.append(img);
						container.append(itemButton);

						countImages.push(img.attribs['src']);
						indexCount = indexCount + 1;
					});
					$grid.append(container);
					setImages(prevImages => [...prevImages, ...countImages]);
				}
			});

			// add wrapper for image
			$('img:not(.photogrid img)').addClass('h-full mt-0 mx-auto max-w-full w-[710px]');
			$('img:not(.photogrid img)').wrap('<div class="img-container sm:items-start items-center"></div>');
			$('iframe').addClass('max-w-full w-full h-full shrink-0');
			$('iframe').wrap(
				'<div class="video-container flex sm:items-start items-center w-full max-w-[640px] mx-auto" ></div>'
			);

			$('figcaption').addClass('');
			setAnchorsData(prevAnchorsData => [...prevAnchorsData, ...newAnchorsData]);

			setContentProcessed($.html());

			addPath({ url: searchParams.get('uuid') || '', name: data?.title });
		}
	}, [data, searchParams.get('uuid')]);

	useEffect(() => {
		const buttons = document.querySelectorAll('.photogrid-item');
		buttons.forEach(button => {
			button.addEventListener('click', handleClickPhoto as unknown as EventListener);
		});

		return () => {
			buttons.forEach(button => {
				button.removeEventListener('click', handleClickPhoto as unknown as EventListener);
			});
		};
	}, [contentProcessed, data]);

	const [eventListeners, setEventListeners] = useState<{ element: HTMLElement; listener: () => void }[]>([]);

	useEffect(() => {
		anchorsData.forEach(data => {
			const element = document.getElementById(data.id);
			if (element) {
				const listener = () => handleClick(data.uuid);
				element.addEventListener('click', listener);
				setEventListeners(prevListeners => [...prevListeners, { element, listener }]);
			}
		});

		return () => {
			eventListeners.forEach(({ element, listener }) => {
				element.removeEventListener('click', listener);
			});
		};
	}, [data, anchorsData]);

	// Process content, add figcaption to each image and create photogrid
	const processContent = (htmlString: string) => {
		const $ = cheerio.load(htmlString);
		$('img:not(.photogrid img)').each((index, element) => {
			const imgAlt = $(element).attr('alt') || '';
			if (imgAlt && imgAlt != 'None') {
				$(element).after(`<figcaption>${imgAlt}</figcaption>`);
			}
		});

		return $.html();
	};

	return (
		<>
			{isFetching ? (
				<>
					<LoadingLayout />
				</>
			) : (
				<>
					<Header logoURL={data?.logo_url} />
					{isOpen && (
						<Lightbox
							key={key.toString()}
							mainSrc={images[photoIndex]}
							nextSrc={images[(photoIndex + 1) % images.length]}
							prevSrc={images[(photoIndex + images.length - 1) % images.length]}
							onCloseRequest={() => {
								setIsOpen(false);
							}}
							onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
							onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
						/>
					)}
					<MediaContent>
						<Heading text={data?.title ?? ''} variant="h1" className={'sm:text-3xl land-sm:text-3xl '} />
						<div className={'blog-content'} dangerouslySetInnerHTML={{ __html: processContent(contentProcessed) }} />
					</MediaContent>
				</>
			)}
		</>
	);
};

export default BlogPage;
