import { API_BLOG } from '@constants/config';
import CreateApiClient from '../api-client';

interface BlogPost {
    uuid: string;
    title: string;
    content: Record<string, any>[];
}
interface BlogPostResponse {
    length: number;
    data: BlogPost[];
}

export class BlogService {
    static async getNotion( uuid: string): Promise<Record<string, any> | undefined> {
        try {

            const client = CreateApiClient(API_BLOG);

            // Make GET request to the API endpoint
            const response = await client.get("/dental/notion/" + uuid);
            const notion = response.data;

            // Return true if the request was successful
            if (notion) {

                const currentUrl = window.location.href;
                // Find the index of '?uuid='
                const indexOfUuid = currentUrl.indexOf('?uuid=');

                // Extract substring starting from the beginning up to and including '?uuid='
                const currentUrlSubstring = currentUrl.substring(0, indexOfUuid + 6); // Adding 6 to include '?uuid='
                         
                notion.content = notion.content.replace(/https:\/\/dev\.mondplan\.nl\/dental\/notion\//g, currentUrlSubstring);
                return notion;
            } else {
                throw new Error('NOT Found')
            }
        } catch (error) {
            console.error('Error fetching blog notion:', error);
            throw new Error('NOT Found')
            //return undefined;
        }
    }
}
