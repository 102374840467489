import React from 'react';
import { IconsProps } from './icons';

export const HomeIcon = ({ className }: IconsProps) => (
	<svg className={className} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M5.75 12H3.75L12.75 3L21.75 12H19.75"
			stroke="#222222"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M5.75 12V19C5.75 19.5304 5.96071 20.0391 6.33579 20.4142C6.71086 20.7893 7.21957 21 7.75 21H17.75C18.2804 21 18.7891 20.7893 19.1642 20.4142C19.5393 20.0391 19.75 19.5304 19.75 19V12"
			stroke="#222222"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9.75 21V15C9.75 14.4696 9.96071 13.9609 10.3358 13.5858C10.7109 13.2107 11.2196 13 11.75 13H13.75C14.2804 13 14.7891 13.2107 15.1642 13.5858C15.5393 13.9609 15.75 14.4696 15.75 15V21"
			stroke="#222222"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
