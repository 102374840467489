import React, { FC } from 'react';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import Logo from '@components/atoms/logo/logo';
import styles from './header.module.scss';
////////////
// Header //
////////////
interface HeaderProps {
	logoURL: string | undefined;
}

const Header: FC<HeaderProps> = ({ logoURL }): JSX.Element => {
	return (
		<div className={styles.header} style={{ borderWidth: '0px 0px 1px 0px' }}>
			<Breadcrumbs />

			<Logo logoURL={logoURL}/>
		</div>
	);
};

export default Header;
