import React from 'react';

interface ChevronIconProps {
	className?: string;
}

export const ChevronIcon = ({ className }: ChevronIconProps) => {
	return (
		<svg
			className={className}
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.75 4L16.75 12L8.75 20"
				stroke="#7684FF"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
