import React, { FC, useState, useEffect } from 'react';
import classNames from 'classnames';
import { MenuIcon } from '../atoms/icons/menuIcon';
import { Arrow } from '../atoms/icons/arrowIcon';
import { useNavigation } from '../../contexts/navigation/navigation';
import style from './Navbar.module.scss';

const Navbar: FC = () => {
	const { paths, getUrl } = useNavigation();
	const [currentTab, setCurrentTab] = useState<number | null>(null);

	// const handleMenu = () => {
	// 	console.log('helo menu');
	// };

	const handleLinks = (url: string, index: number) => {
		if (currentTab === index) {
			return;
		} else {
			setCurrentTab(index);
			getUrl(url);
		}
	};

	useEffect(() => {
		if (paths.length === 1) {
			setCurrentTab(0);
		} else {
			setCurrentTab(paths.length - 1);
		}
	}, [paths]);

	return (
		<div
			className={classNames('top-0 w-full bg-white z-50 md:px-[2rem] px-2', style.Container)}
			style={{ backgroundColor: '#FFF',display:'none' }}
		>
			<nav
				className={classNames(
					style.Navigation,
					'flex items-center gap-4 md:gap-0 justify-between w-full pt-[1.188rem]'
				)}
			>
				<div className="flex-none items-center">
					{/* <button>
						<MenuIcon className={classNames('w-8 h-8')} />
					</button> */}
				</div>

				<ul className="flex items-center w-full gap-[1.75rem]  text-[#212427] cursor-pointer overflow-x-auto md:pl-[2.688rem]">
					{paths.map((path, index) => {
						const isCurrentTab = currentTab === index;
						const isLastTab = index === paths.length - 1;
						return (
							<li className="flex-none" key={index}>
								<button
									className={`flex items-center justify-between gap-[1.75rem] md:text-[20px] text-[16px] cursor-pointer z-50 ${
										isCurrentTab ? 'text-[#7684FF]' : 'text-[#212427] font-normal'
									}`}
									onClick={() => {
										handleLinks(path.url, index);
									}}
								>
									{path.name}
									{!isLastTab && <Arrow className="w-4 h-4" />}
								</button>
							</li>
						);
					})}
				</ul>
				<div className="flex-none items-center w-[100px] md:w-[400px] flex-shrink-0">
					<img src="/svg/logo.svg" alt="TandheelKunde" />
				</div>
			</nav>
		</div>
	);
};

export default Navbar;

// const Navbar: FC = () => {
// 	const { paths, getUrl } = useNavigation();
// 	const [currentTab, setCurrentTab] = useState<number | null>(null);

// 	const handleLinks = (url: string, index: number) => {
// 		if (currentTab === index) {
// 			return;
// 		} else {
// 			setCurrentTab(index);
// 			getUrl(url);
// 		}
// 	};

// 	useEffect(() => {
// 		if (paths.length === 1) {
// 			setCurrentTab(0);
// 		} else {
// 			setCurrentTab(paths.length - 1);
// 		}
// 	}, [paths]);

// 	return (
// 		<div
// 			className={classNames('sticky top-0 w-full bg-white  md:px-[2rem] px-2', style.Container)}
// 			style={{ backgroundColor: '#FFF' }}
// 		>
// 			<nav className={classNames(style.Navigation, 'flex items-center gap-4 md:gap-0  w-full pt-[1.188rem]')}>
// 				<div className="flex-none items-center">
// 					<button>
// 						<MenuIcon className={classNames('w-8 h-8')} />
// 					</button>
// 				</div>
// 				<ul className="flex items-center w-full gap-6 text-[#212427] cursor-pointer overflow-x-auto md:pl-[2.688rem] ">
// 					{paths.map((path, index) => {
// 						const isCurrentTab = currentTab === index;
// 						const isLastTab = index === paths.length - 1;
// 						return (
// 							<li
// 								className="flex-none"
// 								key={index + 1}
// 								onClick={() => {
// 									handleLinks(path.url, index);
// 								}}
// 							>
// 								<button
// 									className={`flex items-center gap-[1.75rem] md:text-[20px] text-[16px] cursor-pointer ${
// 										isCurrentTab ? 'text-[#7684FF]' : 'text-[#212427] font-normal'
// 									}`}
// 								>
// 									{path.name}
// 									{!isLastTab && <Arrow className="w-4 h-4" />}
// 								</button>
// 							</li>
// 						);
// 					})}
// 				</ul>

// 				<div className="flex-none items-center w-[100px] md:w-[200px] flex-shrink-0">
// 					<img src="/svg/logo.svg" alt="TandheelKunde" />
// 				</div>
// 			</nav>
// 		</div>
// 	);
// };

// export default Navbar;
