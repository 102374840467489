import axios from 'axios';

const CreateApiClient = (baseURL?: string) => {


  
  // const tokenRequest = {
  //   method: 'POST',
  //   headers: { 'content-type': 'application/x-www-form-urlencoded', 'accept': 'application/json' },
  //   data: qs.stringify(data),
  //   url: 'https://dev-api.mondplan.nl/users/token/',
  // }

  const apiClient = axios.create({
    baseURL: baseURL || 'https://dev-api.mondplan.nl/',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      // 'Authorization': '',
    }
  });

  // apiClient.interceptors.request.use(
  //   async config => {
  //     axios.request(tokenRequest).then(function (res) {
  //     return res
  //   }).then(function (res) {
  //     config.headers.Authorization = 'Bearer ' + res.data.access_token
  //   })
  //     return config;
  // }, function (error) {
  //   return Promise.reject(error);
  // });


  // apiClient.interceptors.response.use((response) => {
  //   return response
  // }, async function (error) {
  //   const originalRequest = error.config;
  //   if (error.response.status === 401 && !originalRequest._retry) {
  //     originalRequest._retry = true;

  //     const res = await axios.request(tokenRequest)
  //     apiClient.defaults.headers.Authorization = 'Bearer ' + res.data.access_token

  //     return apiClient(originalRequest);
  //   }
  //   return Promise.reject(error);
  // });


  return apiClient;
};

export default CreateApiClient;