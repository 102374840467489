import React, { FC, useEffect } from 'react';
import { HomeIcon } from '@components/atoms/icons/homeIcon';
import { ChevronIcon } from '../atoms/icons/chevronIcon';
import { Breadcrum } from '../atoms/breadcrum/breadcrum';
import { useNavigation } from '@contexts/navigation/navigation';
import { useSearchParams } from 'react-router-dom';
import styles from './breadcrumbs.module.scss';

const Breadcrumbs: FC = (): JSX.Element => {
	const { paths, url, removePathsAfter } = useNavigation();
	const [searchParams] = useSearchParams();
	const myParam = {
		uuid: ''
	};

	myParam.uuid = searchParams.get('uuid') ?? '';

	useEffect(() => {
		removePathsAfter(myParam.uuid);
	}, [myParam.uuid]);

	return (
		<div className={styles.topContainer}>
			<a href="">
				<HomeIcon className={styles.homeIcon} />
			</a>
			<div className="flex flex-wrap overflow-hidden gap-4">
				{paths?.map((path, i) => (
					<div className="flex items-center overflow-hidden gap-4" key={'breadcrumb-' + i}>
						<ChevronIcon className="shrink-0" />
						<Breadcrum uuid={path.url}>{path.name}</Breadcrum>
					</div>
				))}
			</div>
		</div>
	);
};

export default Breadcrumbs;
