import React from 'react';
import { Arrow } from '@components/atoms/icons/arrowIcon';
// import { useNavigate, redirect } from 'react-router-dom';

const NoMatchPage = () => {
	document.title = 'Not found';

	// const navigate = useNavigate();
	return (
		<div className="flex flex-col bg-primary-surface-1 w-full h-screen items-center justify-center p-8">
			<div className="flex flex-1 flex-col items-center justify-center">
				<span className="md:text-[256px] text-[180px] font-bold text-transparent bg-clip-text bg-gradient-to-r from-primary-main to-secondary-main opacity-30">
					404
				</span>
				<span className="font-semibold text-4xl text-neutral-subtitle-2">Page Not Found</span>
				<p className="text-neutral-body-2 text-base">The page you requested was not found or has been removed.</p>
				<div
					className="mt-12 flex flex-col justify-center items-center"
					onClick={() => {
						// navigate('/');
						window.location.href = '/';
					}}
				>
					<button className="flex items-center">
						Go Back <Arrow className="" />
					</button>
				</div>
			</div>
		</div>
	);
};
export default NoMatchPage;
