import React, { createContext, useEffect, useState, useContext, ReactNode } from 'react';
import { API_BLOG } from '../../constants/config';

interface NavigationProviderProps {
	children: ReactNode;
}
interface Paths {
	url: string;
	name: string;
}
interface NavigationContextType {
	paths: Paths[];
	addPath: (path: Paths) => void;
	getUrl: (url: string) => void;
	url: string;
	removePathsAfter: (pathName: string) => void;
}
const NavigationContext = createContext<NavigationContextType | undefined>(undefined);

export function NavigationProvider({ children }: NavigationProviderProps) {
	const [paths, setPaths] = useState<Paths[]>([]);
	const [url, setUrl] = useState<string>(API_BLOG);

	const addPath = (path: Paths) => {
		if (!paths.some(existingPath => existingPath.name === path.name)) {
			setPaths(prevPaths => [...prevPaths, path]);
		} else return;
	};

	const removePathsAfter = (pathName: string) => {
		const index = paths.findIndex(path => path.url === pathName);
		if (index !== -1) {
			setPaths(prevPaths => prevPaths.slice(0, index + 1));
		}
	};

	const getUrl = (url: string) => {
		setUrl(url);
	};

	useEffect(() => {
		sessionStorage.setItem('paths', JSON.stringify(paths ?? [{ id: '1', name: 'Home' }])); //to persist the paths, maybe async storage can be used instead
	}, [paths]);
	useEffect(() => {
		const storedPaths = JSON.parse(sessionStorage.getItem('paths') || '') || [];
		setPaths(storedPaths);
	}, []);
	return (
		<NavigationContext.Provider value={{ paths, addPath, getUrl, url, removePathsAfter }}>
			{children}
		</NavigationContext.Provider>
	);
}

export function useNavigation() {
	const context = useContext(NavigationContext);
	if (!context) {
		throw new Error('Should be within provider');
	}
	return context;
}
